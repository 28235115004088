.layoutContainer {
  box-sizing: border-box;
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  padding-top: 66.66px;
  height: 100%;
  width: 100%;
}
