.footer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: rgb(26, 26, 26);
  color: rgb(228, 228, 228);
  padding: 1rem 2rem;
  border-top: 2px solid grey;
  position: absolute;
  bottom: 0;
}

.footer a {
  color: inherit;
  text-decoration: underline;
}

.footer a:hover {
  color: white;
  font-weight: bolder;
}

.footer a:visited {
  color: inherit;
}

.bold {
  font-weight: bolder;
  color: white;
}

.footer p {
  text-align: left;
  max-width: 120ch;
  margin-bottom: 0px;
}

.footer p:last-child {
  margin-bottom: 16px;
}
