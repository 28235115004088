:root {
  --GREEN: rgb(0, 108, 6);
  --DARKGREEN: rgb(0, 64, 4);
  --BLUE: rgb(9, 74, 121);
  --DARKBLUE: rgb(0, 36, 62);
  --BRIGHTBLUE: rgb(44, 199, 226);
}

html {
  scroll-behavior: smooth;
  background-color: white;
}

section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin: 0;
  scroll-margin-top: 3.5rem;
}

h1 {
  font-size: 2.25rem;
  padding: 0 1rem;
  box-sizing: border-box;
  font-weight: bold;
}

button {
  outline: none;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
button:hover {
  filter: brightness(0.9);
  text-decoration: underline;
}
button:active {
  transform: scale(97%);
}

a {
  color: black;
  text-decoration: none;
}
a:visited {
  color: black;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
a:active {
  transform: scale(95%);
}

.scrollToTop {
  display: none;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 99;
  background-color: white;
  color: black;
  border-radius: 50%;
  border: 1px solid var(--DARKBLUE);
  width: 3rem;
  height: 3rem;
  margin: 0;
  padding: 0.75rem;
}

.scrollToTop.shown {
  display: block;
}

scrollToTop:hover {
  text-decoration: none;
}

.scrollToTop:active {
  transform: scale(95%);
}

.scrollToTop svg {
  fill: var(--DARKBLUE);
  width: 100%;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.App {
  text-align: center;
}
