.process {
  box-sizing: border-box;
  background-color: rgb(232, 250, 255);
  background-image: linear-gradient(45deg, lightblue, rgb(232, 250, 255));
  color: white;
}

.process h1 {
  color: var(--DARKBLUE);
}
