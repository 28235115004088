.teamList {
  margin: 0;
  padding: 0;
}

.teamMember {
  list-style-type: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem 2rem 1rem;
  max-width: 60ch;
}

.teamInfoContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.teamTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 1rem 1rem 0;
  text-align: left;
  flex-shrink: 5;
}

.team h2 {
  text-align: left;
  padding: 0;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
}

.teamTitle {
  font-size: 1.25rem;
  font-weight: bold;
  color: rgb(26, 26, 26);
  padding-bottom: 1rem;
  margin: 0;
  text-align: left;
}

.teamEmail {
  padding-bottom: 0.5rem;
}

.teamEmail:hover {
  color: var(--BLUE);
}

.finraLink:hover {
  text-decoration: underline;
  color: var(--BLUE);
}

.teamBio {
  white-space: pre-line;
  line-height: 2;
  text-align: left;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.teamBioContainer {
  max-height: 6rem;
  overflow: hidden;
  position: relative;
  transition: max-height 0.5s ease 0s;
}

.teamBioContainer:after {
  content: "";
  position: absolute;
  pointer-events: none;
  z-index: 1;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 10%),
    rgba(255, 255, 255, 90%)
  );
  width: 100%;
  height: 4rem;
}

.teamBioContainer.open {
  transition: max-height 0.5s ease 0s;
}

.teamBioContainer.open:after {
  display: none;
}

.teamBioListItem {
  padding-left: 1rem;
  margin: 0;
  text-align: left;
  line-height: 1.5rem;
  list-style-type: disc;
  list-style-position: inside;
  font-style: italic;
}

.teamPhotoContainer {
  max-width: 10rem;
}

.teamPhoto {
  max-width: 100%;
  height: auto;
  aspect-ratio: attr(width) / attr(height);
  border-radius: 15px;
  object-fit: contain;
}

.readMoreButton {
  background-color: transparent;
  color: black;
  padding: 0.5rem;
  margin-top: 1rem;
  box-shadow: none;
}

@media screen and (min-width: 600px) {
  .teamInfoContainer {
    align-items: center;
  }
  .teamMember {
    max-width: 85ch;
  }
}

@media (min-width: 350px) {
  .teamInfoContainer {
    flex-direction: row;
  }
  .teamPhotoContainer {
    order: 1;
  }
}
