.about {
  max-width: 100ch;
  padding: 1rem 2rem 2rem;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
}

.about p {
  text-align: left;
  max-width: 85ch;
  padding: 1rem;
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
  color: black;
}

@media screen and (max-width: 600px) {
  .about p {
    padding: 1rem 0 1rem;
  }
}
