.network ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 0;
  padding: 2rem 0;
  max-width: 1200px;
}

.network li {
  box-sizing: border-box;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem;
}

.network h2 {
  box-sizing: border-box;
  font-weight: normal;
  font-size: 1.25rem;
  padding: 0;
  width: 175px;
}

.networkImgContainer {
  max-width: 100px;
}

.network img {
  background-color: var(--GREEN);
  background-image: linear-gradient(135deg, var(--DARKGREEN), var(--GREEN));
  max-width: 100%;
  height: auto;
  aspect-ratio: attr(width) / attr(height);
  border-radius: 10px;
}

@media (min-width: 350px) {
  .network li {
    flex-direction: row;
    text-align: left;
  }
}
