@font-face {
  font-family: "NunitoBold";
  src: url("/src/assets/fonts/Nunito.ttf") format("truetype");
  font-weight: 800;
  font-display: swap;
}

.errorPage {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.errorTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.errorStatus {
  font-family: "NunitoBold", sans-serif;
  font-size: 5rem;
  margin: 0;
}

.errorLink {
  text-decoration: underline;
  margin: 1rem;
}

.errorLink:hover {
  color: var(--BLUE);
}

.errorIcon {
  max-width: 150px;
}

@media screen and (max-width: 500px) {
  .errorPage {
    height: 100vh;
  }
}
