@font-face {
  font-family: "MontserratBold";
  src: local("MontserratBold"),
    url("/src/assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
}

#hero {
  padding: 5.5rem 1rem 2rem;
  /* background-image: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1)), url('../../assets/images/lake-catamount.png'); */
  background-size: cover;
}

.hero {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: center;
}

.heroTextContainer {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  color: black;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
}

.hero h1 {
  width: 100%;
  font-family: "MontserratBold", "Montserrat", sans-serif;
  font-weight: bolder;
  font-size: 2rem;
  font-size: 10vw;
  margin: 0;
  box-sizing: border-box;
  letter-spacing: 4px;
  padding: 1rem 1rem 0.5rem;
  color: var(--DARKBLUE);
  text-align: left;
  font-variant: small-caps;
}

.hero p {
  color: var(--DARKBLUE);
  font-size: 1.25rem;
  padding: 0 1rem 1.5rem 1rem;
  margin: 0;
  line-height: 1.5;
  max-width: 500px;
}

.hero a {
  padding: 1rem;
}

.hero button {
  background-color: transparent;
  border: 1px solid var(--DARKBLUE);
  color: var(--DARKBLUE);
  padding: 1rem 1.5rem;
  font-weight: bold;
}

.hero button:hover {
  background-color: var(--DARKBLUE);
  color: white;
}

.hero .finra {
  font-size: 1.125rem;
  color: black;
}

.hero .finra a {
  color: black;
  margin: 0;
  padding: 0;
  text-decoration: underline;
}

.hero .finra a:hover {
  color: var(--BLUE);
  fill: var(--BLUE);
}

.heroImgContainer {
  padding: 1rem 2rem 1rem;
  max-width: 300px;
}

.heroImgContainer img {
  max-width: 100%;
  height: auto;
  aspect-ratio: attr(width) / attr(height);
  object-fit: contain;
}

.hero .crs {
  display: flex;
  flex-direction: center;
  align-items: center;
}

.hero .icon {
  width: 1rem;
  padding: 0rem 0.5rem 0rem;
}

@media screen and (min-width: 600px) {
  .hero h1 {
    font-size: 3rem;
  }
}

@media screen and (min-width: 800px) {
  .hero {
    flex-wrap: nowrap;
  }
  #hero {
    padding: 0 1rem;
    height: 100vh;
  }
}

@media screen and (min-width: 1200px) {
  .hero h1 {
    font-size: 4rem;
  }
  .heroImgContainer {
    max-width: 350px;
    padding-left: 5rem;
  }
}
