header {
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  z-index: 99;
  background-color: white;
  word-break: break-word;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}

nav {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.logo {
  padding: 0;
  margin: 0;
  flex-shrink: 0;
}

.logo img {
  display: block;
  width: 10rem;
  height: auto;
  aspect-ratio: attr(width) / attr(height);
}

.linkContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0 1rem;
}

.linkContainer a {
  padding: 16px;
  float: left;
  display: block;
}

.linkContainer .icon {
  display: none;
  border: none;
  background-color: inherit;
  box-shadow: none;
  width: 2rem;
  height: 2rem;
  padding: 0;
  margin: 0;
}

.linkContainer .svg {
  fill: black;
  width: 100%;
}

.linkContainer .svg:active {
  transform: scale(95%);
}

@media screen and (max-width: 850px) {
  nav {
    justify-content: space-between;
  }
  .linkContainer a {
    display: none;
  }
  .linkContainer .icon {
    display: block;
  }
}

@media screen and (max-width: 850px) {
  nav.open {
    align-items: flex-start;
  }
  nav.open .icon {
    margin-top: 1rem;
  }
  nav.open .linkContainer {
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
  }
  nav.open .linkContainer a {
    display: inline-block;
  }
}
