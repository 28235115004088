#contact {
  color: white;
  background-image: linear-gradient(190deg, var(--BLUE), var(--DARKBLUE));
}

.contactContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact p {
  font-size: 1.25rem;
  margin: 0;
  padding: 1rem;
}

.contact .icon {
  fill: goldenrod;
  width: 2rem;
  padding: 1rem 1rem 0rem;
}

.contact a {
  font-size: 1.25rem;
  color: white;
  margin: 0;
  padding: 1rem;
}

.contact a:visited {
  color: white;
}

.mapContainer {
  margin: 2rem 1rem 1rem;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 450px;
}

.map {
  width: 100%;
  height: 100%;
  border: none;
}

@media screen and (min-width: 650px) {
  .mapContainer {
    width: 600px;
  }
}
