@font-face {
  font-family: "Nunito";
  src: url("/src/assets/fonts/Nunito.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
}

.slideViewport {
  box-sizing: border-box;
  overflow: hidden;
  max-width: 1000px;
  width: 100%;
  display: flex;
}

.slides {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  transition: margin-left 0.3s ease;
  cursor: grab;
}

.slides.dragging {
  transition: none 0s ease;
  cursor: grabbing;
}

.slide {
  list-style-type: none;
  box-sizing: border-box;
  margin: 0;
  flex-shrink: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slideContents {
  box-sizing: border-box;
  max-width: 80ch;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.slideIcon {
  aspect-ratio: attr(width) / attr(height);
  max-width: 150px;
  height: auto;
}

.slideText {
  font-family: "Nunito", sans-serif;
  font-size: 1.15rem;
  line-height: 1.25;
  letter-spacing: 1.5px;
  color: var(--DARKBLUE);
  pointer-events: none;
  margin-bottom: 0;
}

.slideControls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem 0 2rem;
}

.slideControlArrows {
  display: flex;
  gap: 1rem;
}

.slideControlArrow {
  padding: 0.5rem;
  margin: 0;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slideControlArrow:hover .icon {
  fill: var(--BRIGHTBLUE);
  stroke: var(--BRIGHTBLUE);
}

.slideControlArrow .icon {
  fill: var(--DARKBLUE);
  stroke: var(--DARKBLUE);
  stroke-width: 32px;
  width: 1rem;
  height: 1rem;
  padding: 0;
  margin: 0;
  overflow: visible;
}

.slideControlDots {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 88px;
}

.slideControlDot {
  padding: 0;
  margin: 0 4px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: var(--DARKBLUE);
  transition: width 0.33s ease-out;
}

.slideControlDot:hover {
  background-color: var(--BRIGHTBLUE);
}

.slideControlDot.active {
  width: 32px;
  border-radius: 4px;
  background-color: var(--BRIGHTBLUE);
  transition: width 0.33s ease-out;
}

@media (min-width: 350px) {
  .slideText {
    font-size: 1.75rem;
  }
  .slideIcon {
    max-width: 200px;
  }
}
